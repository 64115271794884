import React, { Component } from "react";
import { withTranslation } from 'react-i18next';

export class Services extends Component {
  render() {
    const { t } = this.props;
    return (
      <div id="services" className="text-center">
        <div className="container">
          <div className="section-title">
            <h2>{t('ourServices')}</h2>
          </div>
          <div className="row">
            <div key={t('Services.0.name')} className="col-md-4">
              {" "}
              <i className={t('Services.0.icon')}></i>
              <div className="service-desc">
                <h3>{t('Services.0.name')}</h3>
                <p>{t('Services.0.text')}</p>
              </div>
            </div>
            <div key={t('Services.1.name')} className="col-md-4">
              {" "}
              <i className={t('Services.1.icon')}></i>
              <div className="service-desc">
                <h3>{t('Services.1.name')}</h3>
                <p>{t('Services.1.text')}</p>
              </div>
            </div>
            <div key={t('Services.2.name')} className="col-md-4">
              {" "}
              <i className={t('Services.2.icon')}></i>
              <div className="service-desc">
                <h3>{t('Services.2.name')}</h3>
                <p>{t('Services.2.text')}</p>
              </div>
            </div>
            <div key={t('Services.3.name')} className="col-md-4">
              {" "}
              <i className={t('Services.3.icon')}></i>
              <div className="service-desc">
                <h3>{t('Services.3.name')}</h3>
                <p>{t('Services.3.text')}</p>
              </div>
            </div>
            <div key={t('Services.4.name')} className="col-md-4">
              {" "}
              <i className={t('Services.4.icon')}></i>
              <div className="service-desc">
                <h3>{t('Services.4.name')}</h3>
                <p>{t('Services.4.text')}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Services);
