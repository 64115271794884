import React, { Component } from "react";
import { withTranslation } from 'react-i18next';

export class Gallery extends Component {
  render() {
    const { t } = this.props;
    return (
      <div id="portfolio" className="text-center">
        <div className="container">
          <div className="section-title">
            <h2>{t('Navigation.gallery')}</h2>
            <p>
              {t('Gallery.prevProjects')}
            </p>
          </div>
          <div className="row">
            <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', flexDirection: 'row' }} >
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    {" "}
                    <a
                      href="https://aguy-sa.firebaseapp.com/"
                      title="Project Title"
                      data-lightbox-gallery="gallery1"
                    >
                      <div className="hover-text">
                        <h4>{t('Gallery.aguy')}</h4>
                      </div>
                      <img
                        src="img/portfolio/Aguy.png"
                        className="img-responsive"
                        alt="Project Title"
                      />{" "}
                    </a>{" "}
                  </div>
                  <p>{t('Gallery.aguyDesc')}</p>
                </div>
              </div>

              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    {" "}
                    <a
                      href="img/portfolio/cityExplore.png"
                      title="Project Title"
                      data-lightbox-gallery="gallery1"
                    >
                      <div className="hover-text">
                        <h4>{t('Gallery.cityExplore')}</h4>
                      </div>
                      <img
                        src="img/portfolio/cityExplore.png"
                        className="img-responsive"
                        alt="Project Title"
                      />{" "}
                    </a>{" "}
                  </div>
                  <p>{t('Gallery.cityExploreDesc')}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Gallery);
