import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import i18n from 'i18next';
import NavArrows from './navArrows';

export class goals extends Component {
  render() {
    const { t } = this.props;
    return (
      <div style={{ height: "100%", display: 'flex', justifyContent: 'space-between', flexDirection: 'column', alignSelf: 'center' }} id="goals">
        <div className="container" >
          <div className="row" dir={i18n.dir()}>
            <div className="col-xs-12 col-md-6"> <img src="img/goals.jpg" className="img-responsive" alt="" /> </div>
            <div className="col-xs-12 col-md-6">
              <div className="about-text">
                <h2> {t('About.goalsTitle')}</h2>
                <p> {t('About.goals.0')}</p>
                <p> {t('About.goals.1')}</p>
                <p> {t('About.goals.2')}</p>
                <p> {t('About.goals.3')}</p>
              </div>
            </div>
          </div>
        </div>
        <NavArrows
          previous={"#about"}
          next={"#services"}
          backgroundColor={"#000"}
        />
      </div>
    )
  }
}

export default withTranslation()(goals);
